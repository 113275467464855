import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import shortid from 'shortid';
import { Layout } from './Layout';
import { Flex, Box, Text } from './elements';
import { RichText } from '../shared/RichText';

const wind = keyframes`
  from, 20%, 53%, 80%, to {
    transform: rotate(2deg);
  }

  40%, 43% {
    transform: rotate(1deg);
  }

  70% {
    transform: rotate(2deg);
  }

  90% {
    transform: rotate(4deg);
  }
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  width: 80px;
  margin-right: 20px;
  transition: filter 0.1s ease-in-out;

  img {
    width: 80px;
  }

  &:hover {
    filter: grayscale(100%);
  }
`;

const Leaf = styled.img`
  src: url(${({ src }) => src});
  position: absolute;
  bottom: 110%;
  right: 19%;
  width: 40%;
  animation: ${wind} 7s infinite;
  transform-origin: bottom right;
`;

const Leafs = styled.img`
  src: url(${({ src }) => src});
  position: absolute;
  bottom: 93%;
  right: 7.7%;
  width: 30%;
  transition: right 1s ease-in-out;
  animation: ${wind} 9s infinite;
`;

const ContactsPage = ({ data }) => {
  const { site_logo: siteLogo } = data.prismicNavigation.data;
  const { localFile: bgLocalFile } = data.prismicNavigation.data.bg_nav;
  const { body: navigation } = data.prismicNavigation.data;
  const navigationPack = { navigation, bgLocalFile };
  const pageData = data.prismicPage.data.body;
  const [pageBg, contacts, social] = pageData;
  const [leaf, leafes, main] = pageBg.items;

  return (
    <>
      <Layout navigationPack={navigationPack} siteLogo={siteLogo}>
        <Flex p={4} width="100%" style={{ position: 'relative' }}>
          <Box
            style={{
              position: 'fixed',
              bottom: 0,
              right: 0
            }}
            pt={{ _: '26%', md: '22%' }}
            width={{ _: '100%', md: 'calc(100% - 200px)' }}
          >
            <Leaf src={leaf.content_image.url} />
            <Leafs src={leafes.content_image.url} />
            <img
              src={main.content_image.url}
              alt=""
              width="100%"
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0
              }}
            />
          </Box>
          <Box p={{ _: '0', md: '32px' }} pb="20px" mb={{ _: '-40px', md: 0 }}>
            <Text as="h1" pb={5}>
              Contact
            </Text>
            <Box>
              {contacts.items.map(el =>
                el.contact_entry.raw.map(item => (
                  <RichText key={shortid.generate()} raw={item} />
                ))
              )}
            </Box>
            <Flex justifyContent="space-between">
              {social.items.map(el => (
                <SocialLink
                  target="target-blank"
                  key={el.item_title.text}
                  href={el.link}
                >
                  <img src={el.content_image.url} alt="social link" />
                </SocialLink>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Layout>
    </>
  );
};

ContactsPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactsPage;

export const query = graphql`
  fragment ContactsItemsFragment on Query {
    prismicPage(uid: { eq: "contact" }) {
      uid
      data {
        title {
          text
        }
        body {
          ... on PrismicPageBodyBannerWithCaption {
            id
            primary {
              image_banner {
                alt
                localFile {
                  url
                }
              }
            }
          }
          ... on PrismicPageBodyContacts {
            id
            items {
              contact_entry {
                raw {
                  text
                  type
                }
              }
            }
          }
          ... on PrismicPageBodyPageContent {
            id
            items {
              content_image {
                url
              }
              item_title {
                text
              }
              link
            }
          }
        }
      }
    }
  }
`;
