import { graphql } from 'gatsby';
import ContactsPage from '../components/5.ContactsPage';

export default ContactsPage;

export const query = graphql`
  {
    ...LayoutFragment
    ...ContactsItemsFragment
  }
`;
